
function openModal() {

  // check if the clicked element has attribute [data-modal-open]
  document.addEventListener('click', function(e) {

    if (e.target.hasAttribute('data-modal-open')) {

      // get the value of [data-modal-open]
      let modalId = e.target.getAttribute('data-modal-open');
      let modal;

      // find the modal associated with the data attribute
      // then add 'open' classes to the modal and document body
      if (document.querySelector('[data-modal="' + modalId + '"]')) {

        modal = document.querySelector('[data-modal="' + modalId + '"]');

        modal.classList.add('is-open');
        document.body.classList.add('modal-is-open');

      }

    }

  })

}

function closeModal() {

  // check if the clicked element has attribute [data-modal-close]
  document.addEventListener('click', function(e) {

    // find the closest modal container and remove the 'open' class
    // then also remove the 'open' class from document body
    if (e.target.hasAttribute('data-modal-close')) {

      let modal = e.target.closest('.c-modal');

      modal.classList.remove('is-open');
      document.body.classList.remove('modal-is-open');

      if (modal.getAttribute('data-modal-close').length != 0) {
        window.location.href = modal.getAttribute('data-modal-close');
      }

    }

  })

}

window.addEventListener('DOMContentLoaded', function() {

  openModal();
  closeModal();

})
